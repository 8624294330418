.login {
    height: 100vh;
    @media (min-width: 768px) {
        background: url('../../images/login_bg.jpg') no-repeat center center;
        background-size: cover;

        .nav-trans {
            display: block;
        }
        .nav-block {
            display: none;
        }
    }

    @media (max-width: 767px) {
        .nav-trans {
            display: none;
        }
        .nav-block {
            display: block;
        }
    }

    .container {
        position: absolute;
        background: var(--color-background-1);
        color: var(--color-foreground-1);

        .form {
            width: 15rem;
            display: grid;
            justify-items: center;
        }

        @media (max-width: 767px) {
            padding-bottom: 30px;
            padding-left: 10%;
            padding-right: 10%;
            margin-top: 100px;
            width: 80%;
        }

        @media (min-width: 768px) {
            opacity: 0.9;
            padding: 30px;
            padding-left: 50px;
            padding-right: 50px;
            top: 50%;
            left: 50%;
            -ms-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2), 0 1px 5px rgba(0,0,0,0.19);
        }
    }
}
