.tenant {
    // display: grid;
    // grid-template-columns: auto;
    // justify-content: center;
    // min-height: 100vh;
    .form {
        display: grid;
        grid-template-columns: auto;

        .action {
            display: flex;
            justify-content: flex-end;
        }
    }

    .file-upload {
        background-color: var(--color-background-2);
        padding: 6px 12px;
        cursor: pointer;
        input {
            display: none;
        }
        .material-icons {
            display: inline-flex;
            vertical-align: middle;
            margin-right: 5px;
            font-size: 2em;
        }
    }
}