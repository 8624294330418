.oak-attach {
  user-select: none;
  display: flex;
  flex-direction: column;
  .drop-container {
    border-radius: 6px;
    // border: 1px solid var(--color-foreground-4);
    // background-color: var(--color-formelement-bg-2);
    border: 1px dashed var(--color-foreground-1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: var(--metric-typography-12);

    .material-icons {
      font-size: 100px;
    }

    &.dropping {
      //   border: 1px solid var(--color-secondary-1);
      background-color: var(--color-formelement-bg-1);

      .material-icons {
        color: var(--color-secondary-1);
      }
    }
  }
  .file-names {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: var(--color-foreground-3);
  }
  .file-input, .button-only {
    display: flex;
    justify-content: flex-end;

    .file-upload {
      border-radius: 6px;
      // background-color: var(--color-formelement-bg-1);
      // padding: 6px 12px;
      cursor: pointer;
      input {
        display: none;
      }
      //   border: 1px solid var(--color-formelement-fg);
      display: flex;
      align-items: center;
      .action {
        display: flex;
        align-items: center;
        .text {
          &:hover {
            text-decoration: underline;
          }
        }
        .material-icons {
          font-size: 1em;
        }
      }
    }
  }

  .button-only {
    display: flex;
    flex-direction: column;
    align-items: center;
    .action-browse {
      display: flex;
      align-items: center;
      padding: 10px 10px;
      .browse-link {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
