.oak-tab {
  display: flex;
  flex-direction: column;
  .header {
    user-select: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25px, 200px));
    @media (max-width: 767px) {
      grid-template-columns: repeat(auto-fit, minmax(25px, 50px));
    }
    // display: flex;
    // flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-y: hidden;
    .tab {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      color: var(--color-foreground-2);
      white-space: nowrap;
      .material-icons {
        &.active {
          @media (max-width: 767px) {
            background-color: var(--color-background-1);
            border-radius: 6px;
          }
        }
        padding: 10px;
        vertical-align: middle;
      }
      &:hover {
        color: var(--color-forerground-1);
      }
      &.active {
        color: var(--color-secondary-1);
      }
    }
    .label {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  .content {
    overflow-y: hidden;
    &.active {
      display: block;
      animation: slide-up 0.3s ease;
    }
    &.inactive {
      display: none;
    }
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
